.card{
    margin: 0% 5%;
}
.card:hover{
    transform: scale(1.05);
}
.deck{
    margin-top: 30px;
}
.buttons{
    margin-top: 15px;
}